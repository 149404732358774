import React, { Suspense } from 'react';
import PreLoader from './component/Loaders/PreLoader';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Error404 from './pages/Error404';
import Login from './pages/Auth/Login';
import Dashboard from './pages/Admin/Dashboard';
import Transactions from './pages/Admin/Transactions';
import TransactionDetails from './pages/Admin/Transactions/TransactionDetails';
import PrintDetails from './pages/Admin/Transactions/PrintDetails';
import AllCustomers from './pages/Admin/Customer';
import TransCustomersDetail from './pages/Admin/Customer/CustomerDetails';
import CalculatedInterests from './pages/Admin/Interest/CalculatedInterests';
import Earnings from './pages/Admin/Interest/Earnings';
import InterestPayout from './pages/Admin/Interest/InterestPayout';
import Levels from './pages/Admin/KYC/Levels';
import UploadedDocuments from './pages/Admin/KYC/UploadedDocuments';
import UpgradeRequest from './pages/Admin/KYC/UpgradeRequest';
import SavingsWallet from './pages/Admin/Savings/SavingsWallet';
import SavingsPenality from './pages/Admin/Savings/SavingsPenality';
import WalletTransactions from './pages/Admin/Savings/WalletTransactions';
import WithdrawalDate from './pages/Admin/Savings/WithdrawalDate';
import BankTransactions from './pages/Admin/Services/BankTransfers';
import BillTransaction from './pages/Admin/Services/BillTransactions/BillTransaction';
import SwitchCode from './pages/Admin/Services/SwitchCode/SwitchCode';
import CardBatchConfig from './pages/Admin/Cards/CardBatchConfig';
import CardRequest from './pages/Admin/Cards/CardRequest';
import CardRequestBatch from './pages/Admin/Cards/CardRequestBatch';
import RequestNewAccount from './pages/Admin/RequestNewAccount/Index';
import CorporateAccount from './pages/Admin/CorporateAccount/Index';
import AuditLogs from './pages/Admin/AuditLogs/Index';
import Referrals from './pages/Admin/Referrals/Index';
import CorporateForm from './pages/Admin/CorporateAccount/CorporateForm';
import SubscriptionReport from './pages/Admin/Reports/SubscriptionReport';
import CustomerReport from './pages/Admin/Reports/CustomerReport';
import ReportSummary from './pages/Admin/Reports/ReportSummary';
import TransactionReport from './pages/Admin/Reports/TransactionReport';
import NibbsReport from './pages/Admin/Reports/NibbsReport';
import Tickets from './pages/Admin/Dispute/Tickets';
import AllTerminals from './pages/Admin/Terminals/AllTerminals';
import AgentTerminals from './pages/Admin/Terminals/AgentTerminals';
import AllAgents from './pages/Admin/Agents/AllAgents';
import New from './pages/Admin/Agents/AgentForm';
import AgentCategories from './pages/Admin/Agents/AgentCategories';
import Dispute from './pages/Admin/Dispute/Index';
import AllAdmins from './pages/Admin/Admin/AllAdmins';
import AdminCategory from './pages/Admin/Admin/AdminCategory';
import WalletHistories from './pages/Admin/Settings/Wallet/WalletHistories';
import UserWallet from './pages/Admin/Settings/Wallet/UserWallet';
import Beneficiaries from './pages/Admin/Settings/Beneficiaries/Beneficiaries';
import Fees from './pages/Admin/Settings/Fees/Fees';
import RolesConfig from './pages/Admin/Settings/Authorization/RolesConfig';
import ModuleConfig from './pages/Admin/Settings/Authorization/ModuleConfig';
import AssignPermissions from './pages/Admin/Settings/Authorization/AssignPermissions';
import PermissionModuleConfig from './pages/Admin/Settings/Authorization/PermissionModuleConfig';
import TransactionsConfig from './pages/Admin/Settings/Extras/TransactionsConfig';
import SetAboutUs from './pages/Admin/Settings/Extras/SetAboutUs';
import IncentivesConfig from './pages/Admin/Settings/Extras/IncentivesConfig';
import SetPromotions from './pages/Admin/Settings/Extras/SetPromotions';
import Interests from './pages/Admin/Settings/Interests/Interests';
import PosBvnListing from './pages/Admin/PosBvnListing/Index';
import { useAppSelector } from './redux/hooks';
import LoginHistory from './pages/Admin/LoginHistory/LoginHistory';
import ApproveTerminals from './pages/Admin/Terminals/ApproveTerminals';

const DashboardLayout = React.lazy(() => import('./layout/DashboardLayout'));
const AuthLayout = React.lazy(() => import('./layout/AuthLayout'));

const Routing = () => {
    const { details } = useAppSelector(state => state.auth);
    const roles = details?.role_id;

    return (
        <BrowserRouter>
            <Suspense fallback={<PreLoader />}>
                <Routes>
                    {/* AUTH ROUTES */}

                    <Route path="*" element={<Error404 />} />
                    <Route path="/" element={<AuthLayout />}>
                        <Route path="" element={<Login />} />
                    </Route>

                    {/* DASHBOARD */}
                    <Route
                        path="/dashboard"
                        element={<Navigate replace to="/dashboard/home" />}
                    />

                    <Route path="/dashboard" element={<DashboardLayout />}>
                        {/* BASIC ROUTES */}

                        <Route path="home" element={<Dashboard />} />
                        <Route path="transactions" element={<Transactions />} />
                        <Route
                            path="/dashboard/transactions/:name/details"
                            element={<TransactionDetails />}
                        />
                        <Route
                            path="/dashboard/transactions/print-receipt"
                            element={<PrintDetails />}
                        />
                        <Route
                            path="/dashboard/customers"
                            element={<AllCustomers />}
                        />
                        <Route
                            path="/dashboard/push-notification"
                            element={<AllCustomers />}
                        />

                        <Route
                            path="/dashboard/customers/:id"
                            element={<TransCustomersDetail />}
                        />
                        <Route
                            path="/dashboard/calculated-interests"
                            element={<CalculatedInterests />}
                        />
                        <Route
                            path="/dashboard/earnings"
                            element={<Earnings />}
                        />
                        <Route
                            path="/dashboard/pos-bvn-list"
                            element={<PosBvnListing />}
                        />

                        <Route
                            path="/dashboard/interest-payout"
                            element={<InterestPayout />}
                        />
                        <Route
                            path="/dashboard/kyc/levels"
                            element={<Levels />}
                        />
                        <Route
                            path="/dashboard/kyc/uploaded-documents"
                            element={<UploadedDocuments />}
                        />
                        <Route
                            path="/dashboard/kyc/upgrade-request"
                            element={<UpgradeRequest />}
                        />
                        <Route
                            path="/dashboard/savings-wallet"
                            element={<SavingsWallet />}
                        />
                        <Route
                            path="/dashboard/savings-penality"
                            element={<SavingsPenality />}
                        />
                        <Route
                            path="/dashboard/wallet-transactions"
                            element={<WalletTransactions />}
                        />

                        <Route
                            path="/dashboard/withdrawal-date"
                            element={<WithdrawalDate />}
                        />

                        <Route
                            path="/dashboard/services/bank-transactions"
                            element={<BankTransactions />}
                        />
                        <Route
                            path="/dashboard/services/bill-transactions"
                            element={<BillTransaction />}
                        />
                        <Route
                            path="/dashboard/services/switch-code"
                            element={<SwitchCode />}
                        />

                        <Route
                            path="/dashboard/request-new-account"
                            element={<RequestNewAccount />}
                        />

                        <Route
                            path="/dashboard/corporate-account"
                            element={<CorporateAccount />}
                        />
                        <Route
                            path="/dashboard/audit-logs"
                            element={<AuditLogs />}
                        />
                        <Route
                            path="/dashboard/login-histories"
                            element={<LoginHistory />}
                        />

                        <Route
                            path="/dashboard/referrals"
                            element={<Referrals />}
                        />

                        <Route
                            path="/dashboard/corporate-account/:id"
                            element={<CorporateForm />}
                        />
                        {(roles === '1' || roles === '2') && (
                            <>
                                {/* REPORTS PAGES */}
                                <Route
                                    path="/dashboard/report/subscription-report"
                                    element={<SubscriptionReport />}
                                />
                                <Route
                                    path="/dashboard/report/customer-report"
                                    element={<CustomerReport />}
                                />
                                <Route
                                    path="/dashboard/report/report-summary"
                                    element={<ReportSummary />}
                                />
                                <Route
                                    path="/dashboard/report/transaction-report"
                                    element={<TransactionReport />}
                                />
                                <Route
                                    path="/dashboard/report/nibbs-report"
                                    element={<NibbsReport />}
                                />
                            </>
                        )}


                        {/* CARD ROUTES */}
                        {(roles === '1' || roles === '2') && (
                            <>
                                <Route
                                    path="/dashboard/cards/card-batch-config"
                                    element={<CardBatchConfig />}
                                />
                                <Route
                                    path="/dashboard/cards/card-request"
                                    element={<CardRequest />}
                                />
                                <Route
                                    path="/dashboard/cards/card-request-batch"
                                    element={<CardRequestBatch />}
                                />
                            </>
                        )}

                        {/* POS ROUTES */}
                        <Route
                            path="/dashboard/disputes/:id"
                            element={<Tickets />}
                        />
                        <Route
                            path="/dashboard/terminals/all"
                            element={<AllTerminals />}
                        />
                        <Route
                            path="/dashboard/terminals/agents"
                            element={<AgentTerminals />}
                        />
                        <Route
                            path="/dashboard/agents/all"
                            element={<AllAgents />}
                        />
                        <Route
                            path="/dashboard/approve-terminals"
                            element={<ApproveTerminals />}
                        />
                        <Route path="/dashboard/agents/:id" element={<New />} />
                        <Route
                            path="/dashboard/agents/categories"
                            element={<AgentCategories />}
                        />
                        <Route
                            path="/dashboard/disputes"
                            element={<Dispute />}
                        />
                        {/* ADMINS ROUTES*/}

                        {details && roles === '1' && (
                            <>
                                {' '}
                                <Route
                                    path="/dashboard/all-admins"
                                    element={<AllAdmins />}
                                />
                                <Route
                                    path="/dashboard/admin-categories"
                                    element={<AdminCategory />}
                                />
                            </>
                        )}

                        {/* SETTINGS ROUTES */}
                        {roles === '1' && (
                            <>
                                {' '}
                                <Route
                                    path="/dashboard/wallet/wallet-histories"
                                    element={<WalletHistories />}
                                />
                                <Route
                                    path="/dashboard/wallet/user-wallet"
                                    element={<UserWallet />}
                                />
                                <Route
                                    path="/dashboard/settings/beneficiaries"
                                    element={<Beneficiaries />}
                                />
                                <Route
                                    path="/dashboard/settings/fees"
                                    element={<Fees />}
                                />
                                <Route
                                    path="/dashboard/settings/authorizations/roles-config"
                                    element={<RolesConfig />}
                                />
                                <Route
                                    path="/dashboard/settings/authorizations/module-config"
                                    element={<ModuleConfig />}
                                />
                                <Route
                                    path="/dashboard/settings/authorizations/assign-permission"
                                    element={<AssignPermissions />}
                                />
                                <Route
                                    path="/dashboard/settings/authorizations/permission-module-config"
                                    element={<PermissionModuleConfig />}
                                />
                                <Route
                                    path="/dashboard/settings/extras/transaction-config"
                                    element={<TransactionsConfig />}
                                />
                                <Route
                                    path="/dashboard/settings/extras/set-about-us"
                                    element={<SetAboutUs />}
                                />
                                <Route
                                    path="/dashboard/settings/extras/incentive-config"
                                    element={<IncentivesConfig />}
                                />
                                <Route
                                    path="/dashboard/settings/extras/set-promotions"
                                    element={<SetPromotions />}
                                />
                                <Route
                                    path="dashboard/settings/interests"
                                    element={<Interests />}
                                />
                            </>
                        )}
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default Routing;
