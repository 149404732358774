export const dev = {
    base: '',
    baseUrl3Admin: 'http://api.test.getripay.com/api/admin/v3',
    posUrl: 'http://62.173.43.41:9080/api',
    baseUrl: 'http://api.test.getripay.com/api/v3',
    url:'https://api.test.getripay.com/api/v1'
};

export const prod = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    posUrl: process.env.REACT_APP_POS_URL,
    baseUrl3Admin: process.env.REACT_APP_BASE_URL_ADMIN,
    base: process.env.REACT_APP_BASE,
    url:'https://api.test.getripay.com/api/v1'
};

const config = process.env.REACT_APP_ENV === 'production' ? prod : dev;

export default config;
