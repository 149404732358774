import {
    Document,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
} from '@react-pdf/renderer';

function CustomersDetails({details}: {details: any}) {
    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#fff',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            margin: '20 auto',
        },
        logo: {
            flexDirection: 'row',
            backgroundColor: '#0037BA',
            height: 60,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 15,
            paddingRight: 15,
        },
        log: {
            width: 110,
            height: 120,
            border: '1px solid #000000',
        },
        title: {
            fontSize: 20,
            color: 'white',
        },
        details: {
            padding: 20,
        },
        top: {
            flexDirection: 'row',
        },
        left: {
            paddingRight: 5,
        },
        long: {
            borderBottom: '1px solid #656565',
            paddingBottom: 2,
            marginTop: 5,
            marginBottom: 5,
            width: 400,
        },
        longer: {
            borderBottom: '1px solid #656565',
            paddingBottom: 2,
            marginTop: 5,
            marginBottom: 5,
            width: 510,
        },

        short: {
            borderBottom: '1px solid #656565',
            paddingBottom: 2,
            width: 252,
        },
        box: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 15,
            marginTop: 5,
            marginBottom: 5,
        },
        text: {
            fontSize: 12,
        },
        header: {
            fontSize: 8,
            fontWeight: 'semibold',
            color: '#3b3b3b',
            marginBottom: 3,
        },
        pre: {
            fontSize: 8,
            fontWeight: 'semibold',
            color: 'white',
        },
        pri: {
            fontSize: 8,
            padding: 4,
            backgroundColor: '#0037BA',
            marginBottom: 5,
        },

        image: {
            marginVertical: 15,
            marginHorizontal: 0,
            width: 100,
            height: 100,
            objectFit: 'fill',
            objectPositionX: 'center',
            objectPositionY: 'center',
            borderWith: 2,
            padding: 2,
            borderColor: 'blue',
            borderStyle: 'solid',
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.logo}>
                    <Image
                        style={{width: 80, height: 40}}
                        src="https://duronetworks.com/wp-content/uploads/2022/01/patrickgold-m-400x400.png"
                    />
                    <Text style={styles.title}>User's Primary Information</Text>
                </View>

                <View style={styles.details}>
                    <View style={styles.top}>
                        <View style={styles.left}>
                            <View style={styles.pri}>
                                <Text style={styles.pre}>
                                    Primary Information
                                </Text>
                            </View>
                            <View style={styles.long}>
                                <Text style={styles.header}>First Name</Text>
                                <Text style={styles.text}>
                                    {details?.first_name}
                                </Text>
                            </View>
                            <View style={styles.long}>
                                <Text style={styles.header}>Middle Name</Text>
                                <Text style={styles.text}>
                                    {details?.others_name}
                                </Text>
                            </View>
                            <View style={styles.long}>
                                <Text style={styles.header}>Last Name</Text>
                                <Text style={styles.text}>
                                    {details?.last_name}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.log}>
                            <Image
                                style={styles.image}
                                src={details.profile_photo}
                            />
                        </View>
                    </View>
                    <View style={styles.box}>
                        <View style={styles.short}>
                            <Text style={styles.header}>Gender</Text>
                            <Text style={styles.text}>{details?.gender}</Text>
                        </View>
                        <View style={styles.short}>
                            <Text style={styles.header}>Date Of Birth</Text>
                            <Text style={styles.text}>
                                {details?.date_of_birth}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.box}>
                        <View style={styles.short}>
                            <Text style={styles.header}>Phone Number</Text>
                            <Text style={styles.text}>
                                {details?.phone_number}
                            </Text>
                        </View>
                        <View style={styles.short}>
                            <Text style={styles.header}>Email Address</Text>
                            <Text style={styles.text}>{details?.email}</Text>
                        </View>
                    </View>
                    <View style={styles.longer}>
                        <Text style={styles.header}>Residential Address</Text>
                        <Text style={styles.text}>{details?.address}</Text>
                    </View>

                    <View style={styles.box}>
                        <View style={styles.short}>
                            <Text style={styles.header}>State Of Origin</Text>
                            <Text style={styles.text}>Nil</Text>
                        </View>
                        <View style={styles.short}>
                            <Text style={styles.header}>Nationality</Text>
                            <Text style={styles.text}>Nil</Text>
                        </View>
                    </View>
                    <View style={styles.longer}>
                        <Text style={styles.header}>Id Type</Text>
                        <Text style={styles.text}>Nil</Text>
                    </View>
                    <View style={styles.box}></View>
                    {/* NIN */}
                    <View style={styles.pri}>
                        <Text style={styles.pre}>
                            National Identity Information
                        </Text>
                    </View>
                    <View style={styles.box}>
                        <View style={styles.short}>
                            <Text style={styles.header}>First Name</Text>
                            <Text style={styles.text}>
                                {details?.nin_data?.data?.firstName}
                            </Text>
                        </View>
                        <View style={styles.short}>
                            <Text style={styles.header}>Last Name</Text>
                            <Text style={styles.text}>
                                {details?.nin_data?.data?.lastname}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.box}>
                        <View style={styles.short}>
                            <Text style={styles.header}>Middle Name</Text>
                            <Text style={styles.text}>
                                {details?.nin_data?.data?.middlename}
                            </Text>
                        </View>
                        <View style={styles.short}>
                            <Text style={styles.header}>Nationality</Text>
                            <Text style={styles.text}>
                                {details?.nin_data?.data?.birthCountry}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.box}>
                        <View style={styles.short}>
                            <Text style={styles.header}>Phone Number</Text>
                            <Text style={styles.text}>
                                {details?.nin_data?.data?.phoneNumber}
                            </Text>
                        </View>
                        <View style={styles.short}>
                            <Text style={styles.header}>Email</Text>
                            <Text style={styles.text}>
                                {details?.nin_data?.data?.email}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.box}>
                        <View style={styles.short}>
                            <Text style={styles.header}>Religion</Text>
                            <Text style={styles.text}>
                                {details?.nin_data?.data?.religion}
                            </Text>
                        </View>
                        <View style={styles.short}>
                            <Text style={styles.header}>Date Of Birth</Text>
                            <Text style={styles.text}>
                                {details?.nin_data?.data?.dateOfBirth}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.longer}>
                        <Text style={styles.header}>Residential Address</Text>
                        <Text style={styles.text}>
                            {details?.nin_data?.data?.residenceAddressLine1}
                        </Text>
                    </View>
                        <View style={styles.box}></View>
                    {/* BVN*/}
                    <View style={styles.pri}>
                        <Text style={styles.pre}>
                            BVN Information
                        </Text>
                    </View>
                    <View style={styles.box}>
                        <View style={styles.short}>
                            <Text style={styles.header}>First Name</Text>
                            <Text style={styles.text}>
                                {
                                    details?.face_verification_data?.data
                                        ?.firstname
                                }
                            </Text>
                        </View>
                        <View style={styles.short}>
                            <Text style={styles.header}>Last Name</Text>
                            <Text style={styles.text}>
                                {
                                    details?.face_verification_data?.data
                                        ?.lastname
                                }
                            </Text>
                        </View>
                    </View>
                    <View style={styles.box}>
                        <View style={styles.short}>
                            <Text style={styles.header}>Middle Name</Text>
                            <Text style={styles.text}>
                                {
                                    details?.face_verification_data?.data
                                        ?.middlename
                                }
                            </Text>
                        </View>
                        <View style={styles.short}>
                            <Text style={styles.header}>Date Of Birth</Text>
                            <Text style={styles.text}>
                                {
                                    details?.face_verification_data?.data
                                        ?.birthdate
                                }
                            </Text>
                        </View>
                    </View>
                    <View style={styles.box}>
                        <View style={styles.short}>
                            <Text style={styles.header}>Phone Number</Text>
                            <Text style={styles.text}>
                                {details?.face_verification_data?.data?.phone}
                            </Text>
                        </View>
                        <View style={styles.short}>
                            <Text style={styles.header}>Email</Text>
                            <Text style={styles.text}>
                                {details?.face_verification_data?.data?.email}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.box}>
                        <View style={styles.short}>
                            <Text style={styles.header}>Gender</Text>
                            <Text style={styles.text}>
                                {details?.face_verification_data?.data?.gender}
                            </Text>
                        </View>
                        <View style={styles.short}>
                            <Text style={styles.header}>Nationality</Text>
                            <Text style={styles.text}>
                                {
                                    details?.face_verification_data?.data
                                        ?.phoneCountryCode
                                }
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default CustomersDetails;
